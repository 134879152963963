const Landing = ({ handleReadFiles, handleFileInput }) => (
  <div className="landing-page">
    <div style={{ padding: "40px" }}>
      이미지 파일을 pdf로 간편하게 변환하세요
      <br />
      <br />
      사용한 이미지는 서버로 전송되지 않습니다.
      <br />
      <br />
      <a href="https://flikws.notion.site/Ifmage-bc65aa3fdd224686913c16a796f5e774">
        사용 안내
      </a>
    </div>
    <button onClick={handleFileInput} className="big-btn">
      사진 선택
    </button>
    <br />
    <div
      className="dropzone"
      onDragStart={(e) => {}}
      onDrop={(e) => {
        if (e.stopPropagation) {
          e.stopPropagation(); // stops the browser from redirecting.
        }
        if (e.preventDefault) {
          e.preventDefault(); // stops the browser from redirecting.
        }
        let files = e.dataTransfer.files;
        handleReadFiles(files);
      }}
      onDragOver={(e) => {
        if (e.preventDefault) {
          e.preventDefault(); // stops the browser from redirecting.
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
    >
      또는 이곳에 파일을 끌어다 놓으세요
    </div>
  </div>
);

export default Landing;
